.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



.container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #0288D1;
  background: radial-gradient(circle, #039BE5, #01579b);
  z-index: 10000;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  flex-direction: column;
  font-family: sans-serif;
}
.signedIn {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.logo {
  font-family: 'Amaranth', sans-serif;
  font-size: 200%;
}
.logoIcon {
  top: 4px;
  font-size: 32px;
  margin-right: -2px;
  position: relative;
}
.caption {
  margin: 20px 0 40px 0;
  font-family: 'Amaranth', sans-serif;
  font-size: 18px;
  opacity: 0.8;
}
.skip {
  font-weight:lighter;
  color:white;
  opacity: 0.7;
  width: 100%;
  display: block;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
}
.skip:HOVER {
  text-decoration: underline;
}
.firebaseUi {
  min-width: 250px;
}
.button {
  cursor: pointer;
  color: blue;
  text-decoration: underline;
  margin-top: 10px;
}
.firebaseui-container {
  background: rgba(0, 0, 0, 0.05);
  margin-bottom: 15px;
  min-height: 150px;
  padding-top: 10px;
  border-radius: 20px;
  box-shadow: none;
}
.firebaseui-container.firebaseui-page-provider-sign-in {
  background: transparent;
  box-shadow: none;
  min-height: 0;
  margin-bottom: 0;
  padding-top: 0;
}
.firebaseui-container.firebaseui-id-page-callback {
  background: transparent;
  box-shadow: none;
  margin-top: 40px;
  height: 84px;
  min-height: 0;
  margin-bottom: 0;
  padding-top: 0;
}
.firebaseui-card-header {
  display: none;
}
.firebaseui-subtitle, .firebaseui-text {
  color: rgba(255, 255, 255, 0.87);
}
.firebaseui-form-actions .mdl-button--raised.mdl-button--colored.firebaseui-button {
  background: rgba(0, 0, 0, 0.1);
}
.firebaseui-id-dismiss-info-bar {
  display: block;
}
.firebaseui-info-bar {
  border: 0;
  border-radius: 10px;
  left: 5%;
  right: 5%;
  top: 10%;
  bottom: 10%;
}